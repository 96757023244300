<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">UBAH KELOMPOK</p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Kelompok"
            custom-class="is-small"
            :type="{'is-danger': errorMap.nama}"
            :message="errorMap.nama"
          >
            <b-input v-model="kelompok.nama" 
              required
              @input="validateInput('nama')"></b-input>
          </b-field>
          <b-field
            label="Mahasiswa"
            custom-class="is-small"
            :type="{'is-danger': errorMap.stasepspd_set}"
            :message="errorMap.stasepspd_set"
          >
            <b-taginput
              v-model="kelompok.pspd_set"
              :data="pspdTags"
              autocomplete
              field="nama"
              icon="tag"
              type="is-info"
              @typing="getFilteredPSPDTags"
              @input="validate()"
            ></b-taginput>
          </b-field>

          <b-field
            label="Kelompok"
            custom-class="is-small"
            :type="{'is-danger': errorMap.kelompok_set}"
            :message="errorMap.kelompok_set"
          >
            <b-taginput
              v-model="kelompok.kelompok_set"
              @remove="onRemove"
              @add="onAdd"
              :data="kelompokTags"
              autocomplete
              field="nama"
              icon="tag"
              type="is-dark"
              @typing="getFilteredKelompokTags"
              @input="validate()"
            ></b-taginput>
          </b-field>
          <b-field
            v-for="kel in kelompok_pspd_map"
            :key="kel.id"
            :label="kel.nama"
            custom-class="is-small"
          >
            <b-taginput
              v-model="kel.pspdList"
              autocomplete
              :allow-new="false"
              :readonly="true"
              field="nama"
              icon="tag"
              type="is-info"
            ></b-taginput>
          </b-field>

          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Kelompok from "../models/kelompokUpdate.js";

export default {
  name: "KelompokUpdate",
  mixins: [createUpdateMixin],
  data() {
    this.kelompokMdl = new Kelompok();
    this.objectMdl = this.kelompokMdl; // alias yg digunakan di cuMixin
    let obv = this.kelompokMdl.getObservables();
    obv.pspdTags = [];
    obv.pspdList = [];
    obv.kelompokTags = [];
    obv.kelompokList = [];
    obv.kelompok_pspd_map = [];
    return obv;
  },
  methods: {
    validate() {
      this.objectMdl.validate();
    },
    getFilteredPSPDTags(text) {
      this.pspdTags = this.pspdList.filter(option => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    getFilteredKelompokTags(text) {
      this.kelompokTags = this.kelompokList.filter(option => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    onAdd(kelompok) {
      const ppdsURL = `${APP_CONFIG.baseAPIURL}/anggota/pspd/options/`;
      axios.get(ppdsURL, { params: { tag: kelompok.id } }).then(response => {
        this.kelompok_pspd_map.push({
          id: kelompok.id,
          nama: kelompok.nama,
          pspdList: response.data
        });
      });
    },
    onRemove(kelompok) {
      let dataKelSet = this.kelompok_pspd_map.filter(
        kel => kel.id == kelompok.id
      );
      if (dataKelSet.length == 0) return;
      const idx = this.kelompok_pspd_map.indexOf(dataKelSet[0]);
      this.kelompok_pspd_map.splice(idx, 1);
    },
    fetchTagData() {
      const ppdsURL = `${APP_CONFIG.baseAPIURL}/anggota/pspd/options/`;
      axios
        .get(ppdsURL)
        .then(response => {
          this.pspdList = response.data;
        })
        .catch(() => {
          this.pspdList = [];
        });

      const kelURL = `${APP_CONFIG.baseAPIURL}/anggota/tag/options/`;
      axios
        .get(kelURL, { params: { tag: this.kelompok.id } })
        .then(response => {
          this.kelompokList = response.data;
        })
        .catch(() => {
          this.kelompokList = [];
        });
    },
    fetchData() {
      this.objectMdl.load(this.$route.params.id, this.fetchTagData);
    }
  },
  watch: {
    kelompok: {
      handler(newValue, oldValue) {
        if (!this.kelompokMdl.getEdited()) {
          this.setEdited(!!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>


<style scoped lang="scss">
.ppds-header {
  padding-right: unset !important;
}

.ppds-field {
  margin-bottom: unset !important;
}

.is-grey-res {
  color: white !important;
  background-color: #6d6d6d !important;
}
</style>
